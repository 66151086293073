import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'

@Injectable({ providedIn: 'root' })
export class SearchService {

  public subject$: BehaviorSubject<string> = new BehaviorSubject<string>('')

  public search(value: string, forceGlobal: boolean = false): void {
    this.subject$.next(value)
  }

}
